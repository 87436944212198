import Image from "next/image";

const Testimonial = () => {
  const depoiments = [
    {
      name: "Fátima Soares",
      text: "Aulé facilitou minha vida demais, coloco meu filho pra estudar todo dia na plataforma! Tenho paz e nem preciso estudar com ele",
      img: "/novos/depoimento/cliente-fatima.png",
    },
    {
      name: "Roberto Alves",
      text: "Depois que contratei o Aulé não precisei mais ficar brigando com ele pra estudar, ele tem uma rotina de estudo muito mais produtiva e eficiente, as notas dele mudou pra melhor",
      img: "/novos/depoimento/cliente-roberto.png",
    },
    {
      name: "Elisa Dias",
      text: "O Aulé mudou a forma como a minha filha Júlia estuda, hoje ela tem organização e prazer em assistir as aulas da plataforma e fazer as tarefas de casa, matemática deixou de ser um pesadelo pra ela kkk",
      img: "/novos/depoimento/cliente-elisa.png",
    },
  ];
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mb--60">
          <div className="section-title text-center">
            <h2 className="title">O que fala nossos alunos</h2>
          </div>
        </div>
      </div>
      <div className="row g-5">
        {depoiments.map((item, index) => (
          <div className="col-lg-4 col-md-6 col-12" key={index}>
            <div className="rbt-testimonial-box">
              <div className="inner">
                <div className="clint-info-wrapper">
                  <div className="thumb">
                    <Image src={item.img} width={494} height={494} alt={item.name} />
                  </div>
                  <div className="client-info">
                    <h5 className="title">{item.name}</h5>
                  </div>
                </div>
                <div className="description">
                  <p className="subtitle-3">{item.text}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;

