import { ContentApi } from "@/api/content";
import { ShopApi } from "@/api/shop";
import Testimonial from "@/components/testimonial/Testimonial";
import { config } from "@/config/config";
import { GetServerSideProps } from "next";
import Image from "next/image";
import Link from "next/link";

export const getServerSideProps: GetServerSideProps = async ({ preview = false }) => {
  let productList: ProductShopListDto[] = [];
  let categoryList: ShopCategoryListSimplifiedDto[] = [];
  let blogList: PostBlogListDto[] = [];
  let highlightsList: PostHighlightsListDto[] = [];
  let faqList: PostPublicFaqListDto[] = [];

  try {
    await ShopApi.GetProductShopList({
      fixedOrSoon: true,
      noPagination: true,
    }).then((ret) => {
      productList = ret.result;
    });
  } catch (ex) {}

  try {
    await ShopApi.CategoryListSimplified({ noPagination: true }).then((ret) => {
      categoryList = ret.result;
    });
  } catch (ex) {}

  try {
    await ContentApi.GetBlogList({ limit: 3, channels: [config.contentChannelHome] }).then((ret) => {
      blogList = ret.result;
    });
  } catch (ex) {}

  try {
    await ContentApi.GetHighlightsList({ limit: 1 }).then((ret) => {
      highlightsList = ret.result;
    });
  } catch (ex) {}

  try {
    await ContentApi.getFaqs({ channels: [config.contentChannelFaqHome] }).then((res) => {
      faqList = res.data;
    });
  } catch (ex) {}

  return {
    props: {
      productList: productList,
      categoryList: categoryList,
      blogList: blogList,
      highlightsList: highlightsList,
      faqList: faqList,
    },
  };
};

type Props = {
  productList: ProductShopListDto[];
  categoryList: ShopCategoryListSimplifiedDto[];
  blogList: PostBlogListDto[];
  highlightsList: PostHighlightsListDto[];
  faqList: PostPublicFaqListDto[];
};

export default function Home({ productList, categoryList, blogList, highlightsList, faqList }: Props) {
  return (
    <div>
      <div className="rbt-rbt-blog-area rbt-section-gapTop bg-color-secondary rbt-round-bottom-shape">
        <div className="wrapper pb--50 rbt-index-upper">
          <div className="container">
            <div className="row g-5 align-items-end mb--60">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="section-title text-start">
                  <h2 className="title color-white">Seu filho pode aprender mais e melhor – com menos esforço!</h2>
                  <p className="description fs-2 color-white mt--20">
                    <strong>Aulé é a plataforma que ajuda seu filho a estudar de forma leve, organizada e eficiente.</strong> Aulas curtas, plano de estudos personalizado e
                    acompanhamento simplificado para pais e alunos.
                  </p>
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                <div className="rbt-card variation-02 rbt-hover card-minimal">
                  <div className="rbt-card-body">
                    <h4 className="rbt-card-title">✅ Todas as matérias do Ensino Fundamental II</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                <div className="rbt-card variation-02 rbt-hover card-minimal">
                  <div className="rbt-card-body">
                    <h4 className="rbt-card-title">✅ Apenas 15 minutos de estudo por dia</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                <div className="rbt-card variation-02 rbt-hover card-minimal">
                  <div className="rbt-card-body">
                    <h4 className="rbt-card-title">✅ Resultados reais e sem estresse</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 text-center">
        <Link href={config.dash_shop} className="rbt-btn btn-secondary radius-round">
          Experimente agora
        </Link>
      </div>
      <div className="rbt-about-area about-style-1 bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 d-flex justify-content-center">
              <Image
                src="/novos/img/teo-do-aule-grande.png"
                width="0"
                height="0"
                sizes="100vw"
                className="w-100 h-100 radius-10"
                alt="Aulé - O reforço que seu filho precisa para aprender"
                loading="eager"
              />
            </div>
            <div className="col-lg-6">
              <div className="inner pl--50 pl_sm--5">
                <div className="section-title text-start">
                  <h2 className="title">Mais aprendizado, menos estresse.</h2>
                  <p className="description mt--20">
                    <strong>Muitos alunos enfrentam dificuldades porque não têm um método certo de estudo.</strong> O Aulé resolve isso de forma simples!
                  </p>
                  <div className="read-more-btn mt--40">
                    <ul>
                      <li>
                        <strong>Plano de estudos personalizado:</strong> Seu filho estuda o que mais precisa
                      </li>
                      <li>
                        <strong>Aulas curtas e diretas:</strong> Apenas 15 minutos por dia para resultados reais
                      </li>
                      <li>
                        <strong>Acompanhamento fácil para os pais:</strong> Você vê o progresso sem complicações
                      </li>
                      <li>
                        <strong>Preço acessível:</strong> Todas as matérias por um único valor mensal
                      </li>
                    </ul>
                    <Link href={config.dash_shop} className="rbt-btn btn-secondary radius-round">
                      Garanta o acesso do seu filho hoje
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rbt-course-area dark-bg-color-white bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row g-5">
            {productList.map((item, index) => (
              <div key={index} className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 sal-animate">
                <div className="rbt-card variation-01 rbt-hover">
                  <div className="rbt-card-img" style={{ marginTop: "-30px", marginLeft: "-30px", marginRight: "-30px" }}>
                    <Link href={`${config.dash_shop}detalhe/${item.friendlyUrl}`}>
                      <Image alt={item.name} src={item.thumbnail ?? config.emptyImage} width="0" height="0" sizes="100vw" className="w-100 h-100 rounded" />
                    </Link>
                  </div>
                  <div className="rbt-card-body">
                    <h4 className="rbt-card-title">
                      <Link href={`${config.dash_shop}detalhe/${item.friendlyUrl}`}>{item.name}</Link>
                    </h4>
                    {item.release && <p className="rbt-card-text">{item.release}</p>}

                    <div className="rbt-card-bottom">
                      <Link className="rbt-btn-link" href={`${config.dash_shop}detalhe/${item.friendlyUrl}`}>
                        veja mais
                        <i className="feather-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {config.enableModules.showTestimony && (
        <div className="rbt-testimonial-area bg-color-extra2 rbt-section-gap">
          <Testimonial />
        </div>
      )}

      <div className="rbt-call-to-action-area rbt-section-gap bg-gradient-8">
        <div className="container">
          <div className="row g-5 align-items-center content-wrapper">
            <div className="col-xxl-3 col-xl-3 col-lg-6">
              <div className="inner">
                <div className="content text-start">
                  <h2 className="title text-dark mb-2">Assine agora</h2>
                  <h6 className="title fs-2 text-dark">Planos a partir de R$ 19,99!</h6>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="inner-content">
                <p className="text-dark">
                  Pronto para transformar a educação do seu filho? Comece hoje mesmo a ajudar seu filho a alcançar melhores resultados nos estudos, com aulas dinâmicas e suporte
                  que fazem a diferença. Assine a Aulé e veja os resultados!
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6">
              <div className="call-to-btn text-start text-xl-end">
                <Link href={config.dash_shop} className="rbt-btn btn-secondary radius-round">
                  Assine agora
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-accordion-area accordion-style-1 bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-10 offset-lg-1">
              <div className="rbt-accordion-style accordion">
                <div className="section-title text-center mb--60">
                  <span className="subtitle bg-pink-opacity">FAQ</span>
                  <h2 className="title">Ficou com dúvida</h2>
                </div>
                <div className="rbt-accordion-style rbt-accordion-04 accordion">
                  <div className="accordion" id="accordionExamplec3">
                    {faqList.map((item, index) => (
                      <div className="accordion-item card" key={index}>
                        <h2 className="accordion-header card-header" id={item.id}>
                          <button
                            className={`accordion-button collapsed`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#div-${index}`}
                            aria-expanded={false}
                            aria-controls={`#div-${index}`}
                          >
                            {item.title}
                          </button>
                        </h2>
                        <div id={`div-${index}`} className={`accordion-collapse collapse`} aria-labelledby={item.title} data-bs-parent="#accordionExamplec3">
                          <div className="accordion-body card-body" dangerouslySetInnerHTML={{ __html: item.text }} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {config.enableModules.showNews && (
        <div className="rbt-rbt-blog-area rbt-section-gapTop bg-color-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--60">
                <div className="section-title text-center">
                  <h2 className="title">Notícias Aulé</h2>
                </div>
              </div>
            </div>
            <div className="row g-5">
              {blogList.map((item, index) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={index}>
                  <div className="rbt-card variation-02 rbt-hover">
                    <div className="rbt-card-img">
                      <Link href={`/blog/post/${item.friendlyUrl}`}>
                        <Image src={item.thumbnail ?? config.emptyImage} width={450} height={267} priority alt={item.title} />
                      </Link>
                    </div>
                    <div className="rbt-card-body">
                      <h5 className="rbt-card-title">
                        <Link href={`/blog/post/${item.friendlyUrl}`}>{item.title}</Link>
                      </h5>
                      <p className="rbt-card-text">{item.release}</p>
                      <div className="rbt-card-bottom">
                        <Link className="transparent-button" href={`/blog/post/${item.friendlyUrl}`}>
                          Veja mais
                          <i>
                            <svg width="17" height="12" xmlns="http://www.w3.org/2000/svg">
                              <g stroke="#27374D" fill="none" fillRule="evenodd">
                                <path d="M10.614 0l5.629 5.629-5.63 5.629" />
                                <path strokeLinecap="square" d="M.663 5.572h14.594" />
                              </g>
                            </svg>
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

